import Button from 'components/layout/button'
import React, { FC, useState } from 'react'
import Image from 'next/image'
import Modal from 'components/modal/modal'
import { selectContent } from 'store/contentSlice'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import Paragraph from 'components/layout/paragraph'
import H1 from 'components/layout/h1'
import H2 from 'components/layout/h2'
import { selectProductData } from 'store/productSlice'
import { updateUserConnection } from 'services/firebaseService'
import { setActiveView, View } from 'store/viewSlice'
import {
  setAdventures,
  setEmail,
  setUnconnected,
  setUserName
} from 'store/userSlice'
import ContentBlock from 'components/layout/contentBlock'
import MailToLink from 'components/mailToLink'

interface Props {
  closeCoCreateView: () => void
}

const CoCreateView: FC<Props> = ({ closeCoCreateView }) => {
  const [isModalOpen, setModalState] = useState(true)
  const toggleModal = () => {
    setModalState(!isModalOpen)
    closeCoCreateView()
  }

  const dispatch = useAppDispatch()
  const productId = useAppSelector(selectProductData)

  const { coCreateView } = useAppSelector(selectContent)
  const { name } = useAppSelector(selectProductData)

  const updateUser = () => {
    dispatch(setUserName(''))
    dispatch(setEmail(''))
    dispatch(setAdventures([]))
    dispatch(setUnconnected())
    updateUserConnection(productId.productId)
    dispatch(setActiveView(View.NOT_CONNECTED_START))
  }

  if (!isModalOpen) {
    return null
  } else {
    return (
      <Modal
        isFullscreen={true}
        isClosable={true}
        isTransparent={false}
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <div className="text-center items-center">
          <H1>{coCreateView?.title}</H1>
          <H2 className="mb-40"> (This page is under construction)</H2>

          {coCreateView?.contentBlocks[String(name)] &&
            coCreateView?.contentBlocks[String(name)]?.map((contentBlock) => (
              <ContentBlock
                key={contentBlock.heading}
                heading={contentBlock.heading}
                icon={contentBlock.icon}
              >
                {contentBlock.textParagraphs.map((text) => (
                  <Paragraph className="mb-30 px-4">{text}</Paragraph>
                ))}

                {contentBlock?.ctaButtons &&
                  contentBlock?.ctaButtons.map((button) => (
                    <Button
                      onClick={() =>
                        button.targetUrl === 'disconnect' && updateUser()
                      }
                      primary={button.isPrimary}
                      isDisabled={button.isDisabled}
                    >
                      {button.email ? (
                        <MailToLink
                          email={button.email}
                          subject={button.subject}
                          body={button.body}
                          linkText={button.buttonText}
                        />
                      ) : (
                        button.buttonText
                      )}
                    </Button>
                  ))}
              </ContentBlock>
            ))}

          <div className="w-12 h-12 fixed right-4 bottom-6">
            <Button
              className="w-full flex items-center justify-center"
              onClick={() => toggleModal()}
            >
              <Image
                src={'/images/Icon-Close.svg'}
                alt={''}
                height="30"
                width="30"
              />
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}
export default CoCreateView
