import React, { FC, useEffect, useState } from 'react'
import StoryBubble from '../storyBubble'
import { setConnected, selectUser, setAdventures } from 'store/userSlice'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { selectProductData } from 'store/productSlice'
import { setErrorMessage, setErrorTitle } from 'store/errorSlice'
import ProductTitle from 'components/layout/productTitle'
import Section from 'components/layout/section'
import { motion } from 'framer-motion'
import { setActiveView, View } from 'store/viewSlice'
import { selectContent } from 'store/contentSlice'
import { selectViewport } from 'store/viewportSlice'
import FirstConnectAnimatedImage from 'components/carousel/firstConnectAnimatedImage'
import useScrollToTop from '../../hooks/useScrollToTop'
import { setCenteredLower } from 'store/backgroundSlice'
import Paragraph from 'components/layout/paragraph'

interface Props {
  title: string
  bubbleText?: string
}

const connectedAdventure = [
  {
    advID: 116,
    type: 'Connect',
    date: new Date().toString(),
    text: 'We finally connected',
    paths: [
      {
        path: 'M0,41.52c25.45,0,42.7-1,52.71-3.58C75.19,32.15,76.42,20.38,76.1,16.27c-.41-5.12-5-10.38-9.51-7.84s-8,8-7,11.14,3.77,2.26,3.52-1.08S57.92,8.7,53.31,8.05C48.15,7.31,45,13.9,46.55,20.67c1,4.15,4.85,15.77,25.85,18.51,17.89,2.34,33.05,2.34,47.6,2.34',
        transformation: ''
      }
    ]
  }
]

const TapAndConnectView: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch()
  const { userName, email, connected, consent } = useAppSelector(selectUser)
  const { productId } = useAppSelector(selectProductData)

  const text = useAppSelector(selectContent)
  const viewportSize = useAppSelector(selectViewport)
  const circleSize = viewportSize === 'small' ? '-10 -25 120 120' : '5 5 90 90'

  const [shouldShowHeart, setShowHeart] = useState(false)
  const [isFirstUpdate, setFirstUpdate] = useState(true)

  useScrollToTop()

  useEffect(() => {
    viewportSize === 'small' && dispatch(setCenteredLower())
  }, [])

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      (e) => {
        e.preventDefault()
      },
      false
    )
  }, [window])

  const touchDuration = 2200
  let timer: NodeJS.Timeout

  useEffect(() => {
    if (isFirstUpdate) setFirstUpdate(false)
    if (shouldShowHeart)
      timer = setTimeout(() => {
        createUser()
      }, touchDuration)

    return () => {
      clearTimeout(timer)
    }
  }, [shouldShowHeart])

  const createUser = async () => {
    const productGender = title.includes("M's ") ? "M's " : "W's "
    const productName = title.replaceAll(productGender, '')
    const res = await fetch('/api/saveUser', {
      body: JSON.stringify({
        userName,
        email,
        productId,
        connectedAdventure,
        productName,
        consent
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    const result = await res.json()
    if (result.text === 'connected') {
      dispatch(setConnected())
      setShowHeart(false)
      dispatch(setAdventures(connectedAdventure))
      const timer = setTimeout(() => {
        dispatch(setActiveView(View.INTRO_CREATION))
      }, 2000)
      return () => clearTimeout(timer)
    } else
      dispatch(setErrorTitle("Ouch! Couldn't connect.")) &&
        dispatch(
          setErrorMessage("Make sure you're online and give it another go!")
        )
  }

  return (
    <>
      <Section className="select-none pt-5 grid items-center w-full h-full">
        <div className="h-full select-none">
          <ProductTitle className="mb-3" name={title}></ProductTitle>
          {shouldShowHeart ? (
            <div className="flex justify-center">
              <FirstConnectAnimatedImage
                className="flex w-1/2 h-43px m-auto mt-10"
                paths={connectedAdventure[0].paths}
                shouldAnimate={true}
              />
            </div>
          ) : (
            <StoryBubble>
              {connected ? (
                <>
                  {text.tapView[2]} {userName} !
                </>
              ) : (
                <>
                  {text.tapView[0]}
                  <br />
                  {text.tapView[1]}
                </>
              )}
            </StoryBubble>
          )}
        </div>
        {!connected && !shouldShowHeart && (
          <Paragraph className="select-none text-xs w-full text-center self-end pb-24">
            {text.tapView[3]}
          </Paragraph>
        )}
      </Section>
      {!connected && (
        <>
          <div
            className="absolute bg-all bg-centered"
            onMouseDown={() => setShowHeart(true)}
            onMouseUp={() => setShowHeart(false)}
            onTouchCancel={() => setShowHeart(false)}
            onTouchStart={() => setShowHeart(true)}
            onTouchEnd={() => setShowHeart(false)}
            onDragEnd={() => setShowHeart(false)}
            onContextMenu={(e) => {
              e.preventDefault()
              return true
            }}
          >
            <motion.svg
              overflow="visible"
              opacity="0.2"
              initial="start"
              className="absolute bg-all bg-centered"
              strokeWidth="0.4"
              width="100"
              height="100"
              viewBox={circleSize}
              fill="none"
            >
              <motion.path
                stroke="black"
                variants={{
                  start: {
                    pathLength: 1,
                    rotate: -90
                  }
                }}
                d="M 97.5 50 C 97.5 76.8001 75.9955 98.5 49.5 98.5 C 23.0045 98.5 1.5 76.8001 1.5 50 C 1.5 23.1999 23.0045 1.5 49.5 1.5 C 75.9955 1.5 97.5 23.1999 97.5 50 Z"
              />
            </motion.svg>
            {shouldShowHeart && (
              <motion.svg
                overflow="visible"
                initial="hidden"
                animate="whileTap"
                exit="exit"
                className="absolute bg-all bg-centered"
                strokeWidth="0.4"
                width="100"
                height="100"
                viewBox={circleSize}
                fill="none"
              >
                <motion.path
                  key="tapCircle"
                  variants={{
                    hidden: {
                      pathLength: 0,
                      rotate: -90,
                      stroke: 'black',
                      transition: {
                        duration: 1
                      }
                    },

                    whileTap: {
                      pathLength: [0, 1],
                      stroke: 'black',
                      opacity: 0.6,
                      transition: {
                        delay: 0.1,
                        duration: 2.4
                      }
                    }
                  }}
                  d="M 97.5 50 C 97.5 76.8001 75.9955 98.5 49.5 98.5 C 23.0045 98.5 1.5 76.8001 1.5 50 C 1.5 23.1999 23.0045 1.5 49.5 1.5 C 75.9955 1.5 97.5 23.1999 97.5 50 Z"
                />
              </motion.svg>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default TapAndConnectView
