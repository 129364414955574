import React, { FC, useState } from 'react'
import Image from 'next/image'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import Button from 'components/layout/button'
import Modal from 'components/modal/modal'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { selectProductData } from 'store/productSlice'
import { setAdventures, selectUser } from 'store/userSlice'
import { selectContent } from 'store/contentSlice'
import { Adventure } from 'store/types'
import { setBottom } from 'store/backgroundSlice'

import {
  adventureAnimation,
  adventureAnimationButton
} from 'public/framerMotionVariants'
import { setErrorMessage, setErrorTitle } from 'store/errorSlice'
import AnimatedImage from 'components/carousel/animatedImage'
import { setActiveAdventure } from 'store/exploreSlice'

interface Props {
  closeAdventureView: () => void
  inspirationAdventure: (t: boolean) => void
}

const AdventureView: FC<Props> = ({
  closeAdventureView,
  inspirationAdventure
}) => {
  const [isModalOpen, setModalState] = useState(true)
  const toggleModal = () => {
    setModalState(!isModalOpen)
    closeAdventureView()
  }

  const dispatch = useAppDispatch()

  const adventuresList = useAppSelector(selectContent).adventuresList
  const { productId } = useAppSelector(selectProductData)
  let { adventures } = useAppSelector(selectUser)

  const [active, setActive] = useState<Adventure>()
  const [selectedActivity, setSelectedActivity] = useState<Adventure>()

  const adventure = selectedActivity

  const addAdventure = async () => {
    const today = new Date().toString()
    const selectedActivity: Adventure = {
      ...adventure,
      date: today
    }
    adventures = [...adventures, selectedActivity]

    const res = await fetch('/api/updateAdventure', {
      body: JSON.stringify({
        productId,
        adventures
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    const result = await res.json()
    if (result.text === 'ok') {
      dispatch(setAdventures(adventures))
      dispatch(setBottom())
      dispatch(setActiveAdventure(selectedActivity))
      inspirationAdventure(true)
      toggleModal()
    } else {
      dispatch(setErrorTitle("Ouch! Couldn't add the adventure.")) &&
        dispatch(
          setErrorMessage("Make sure you're online and give it another go!")
        )
    }
  }

  const chooseActivity = (adventure: Adventure) => {
    setSelectedActivity(adventure)
    setActive(adventure)
  }

  return (
    <>
      {isModalOpen ? (
        <div>
          <Modal
            isFullscreen={true}
            isClosable={true}
            isTransparent={false}
            isOpen={isModalOpen}
            onClose={toggleModal}
          >
            <div className="text-center items-center">
              <div className="absolute left-0 w-11/12 h-6 z-10 my-7 bg-gradient-to-b from-white"></div>
              <div className="absolute left-0 bg-white w-full h-14 rounded-full z-10 -my-7">
                <h1 className="my-6">Add adventure</h1>
              </div>
              <div className="py-8 text-13">
                <motion.div
                  key="adventure"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={adventureAnimation}
                >
                  <ul className="grid grid-cols-2">
                    {adventuresList.map(
                      (adventure, index) =>
                        adventure.type != 'Connect' && (
                          <li className="flex justify-center" key={index}>
                            <div
                              onClick={() => chooseActivity(Object(adventure))}
                            >
                              <div
                                className={cn(
                                  [
                                    'h-125 w-125 bg-gray rounded-full my-2 items-center flex overflow-hidden'
                                  ],
                                  active === adventure
                                    ? ['border border-black']
                                    : ''
                                )}
                              >
                                <AnimatedImage
                                  className={'h-44 m-auto'}
                                  strokeWidth={0.5}
                                  paths={adventure.paths}
                                  orderOfItem={index}
                                ></AnimatedImage>
                              </div>
                              <div className="w-125 h-auto">
                                {adventure['text']}
                              </div>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </motion.div>
                <div className="fixed flex z-10 left-5 right-5 bottom-6">
                  {selectedActivity && (
                    <>
                      <AnimatePresence exitBeforeEnter>
                        <motion.div
                          className=" h-12 flex-grow mr-2"
                          key="addBtn"
                          initial="hidden"
                          animate="visible"
                          variants={adventureAnimationButton}
                        >
                          <Button primary onClick={() => addAdventure()}>
                            Add
                          </Button>
                        </motion.div>
                      </AnimatePresence>
                      <div className="w-12 h-12">
                        <Button
                          className="w-full flex items-center justify-center"
                          onClick={() => chooseActivity(null)}
                        >
                          <Image
                            alt="close"
                            src={'/images/Icon-Close.svg'}
                            height="30"
                            width="30"
                          />
                        </Button>
                      </div>
                    </>
                  )}
                  {!selectedActivity && (
                    <div className="w-full flex justify-end">
                      <div className="w-12 h-12">
                        <Button
                          className="flex items-center justify-center"
                          onClick={() => toggleModal()}
                        >
                          <Image
                            alt="close"
                            src={'/images/Icon-Close.svg'}
                            height="30"
                            width="30"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="fixed left-0 bottom-0 w-full h-20 bg-gradient-to-t from-white"></div>
                <div className="fixed left-0 bottom-0 w-full h-1 bg-white"></div>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default AdventureView
