import Head from 'next/head';
import Background from './background';

export const siteTitle = 'Connected Products';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className='app-default overflow-hidden h-screen bg-gray w-screen'>
      <Head>
        <link rel='icon' href='/favicon.webp' />
        <meta name='og:title' content={siteTitle} />
      </Head>
      <main className='relative h-full w-full'>
        <Background />
        <div className='h-full w-full'>{children}</div>
      </main>
    </div>
  );
}
