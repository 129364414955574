import React, { FC, ReactNode, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Pagination from './pagination';
import { selectViewport } from 'store/viewportSlice';
import { useAppSelector } from 'store/reduxHooks';
import cn from 'classnames';
import { SlideShowItems } from 'store/types';
import Paragraph from 'components/layout/paragraph';

interface Props {
  children?: ReactNode;
  className?: string;
  slideShowItems: SlideShowItems;
}

const SmallCarousel: FC<Props> = ({ slideShowItems, className }) => {
  const viewportSize = useAppSelector(selectViewport);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const updateCarousel = (index: number) => {
    setCarouselIndex(index);
  };

  return (
    <div className={cn(['w-full flex items-center justify-center'], viewportSize === 'small' ? ['mb-10'] : [''])}>
      <div className="flex-col w-full max-w-320">
        <Carousel
          swipeScrollTolerance={50}
          showThumbs={false}
          swipeable={true}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={false}
          emulateTouch={true}
          onChange={updateCarousel}
        >
          {slideShowItems.map((item, index) => (
            <div key={index}>
              <div
                className={cn(
                  ['overflow-hidden rounded-full max-h-320 max-w-320 w-full select-none'],
                  className
                )}
              >
                <img className={'min-h-full min-w-full'} src={item.image}></img>
              </div>
              <Paragraph className="font-goudy-old-style py-4 px-5">{item.text}</Paragraph>
            </div>
          ))}
        </Carousel>
        <div className={cn(['flex w-full justify-center mt-5 mb-5'])}>
          {slideShowItems.map((items, index) =>
            carouselIndex === index ? (
              <Pagination
                key={index}
                viewportSize={viewportSize}
                active={true}
              />
            ) : (
              <Pagination
                key={index}
                viewportSize={viewportSize}
                active={false}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default SmallCarousel;
