import React, { FC, useState } from 'react'
import Image from 'next/image'
import Button from 'components/layout/button'
import Modal from 'components/modal/modal'
import YoutubePlayer from 'components/youtubePlayer'
import { useAppSelector } from 'store/reduxHooks'
import { selectContent } from 'store/contentSlice'
import { selectProductData } from 'store/productSlice'
import SmallCarousel from 'components/carousel/smallCarousel'
import H1 from 'components/layout/h1'
import Paragraph from 'components/layout/paragraph'
import ContentBlock from 'components/layout/contentBlock'
import MailToLink from 'components/mailToLink'

interface Props {
  closeUseMeView: () => void
}

const UseMeView: FC<Props> = ({ closeUseMeView }) => {
  const [isModalOpen, setModalState] = useState(true)
  const toggleModal = () => {
    setModalState(!isModalOpen)
    closeUseMeView()
  }

  const { name } = useAppSelector(selectProductData)
  const content = useAppSelector(selectContent).useMeView

  if (!isModalOpen) {
    return null
  } else {
    return (
      <Modal
        isFullscreen={true}
        isClosable={true}
        isTransparent={false}
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <div className="text-center items-center">
          <H1>{content.header['title']}</H1>
          <Paragraph className="py-4 px-8 mb-4">
            {content.header['preamble']}
          </Paragraph>

          {content.sections[String(name)] &&
            content.sections[String(name)].map((section, i) => {
              // Hide inspiration block if there's no content/video to display
              if (
                section.title === 'Inspiration' &&
                section.video?.length === 0
              ) {
                return undefined
              }

              return (
                <div key={i} className="py-2">
                  <ContentBlock
                    key={section.title}
                    heading={section.title}
                    icon={section.icon}
                  >
                    {section?.preamble && (
                      <Paragraph className="mb-30 px-4">
                        {section?.preamble}
                      </Paragraph>
                    )}

                    {section['slideShowImages'] && (
                      <SmallCarousel
                        slideShowItems={section['slideShowImages']}
                      ></SmallCarousel>
                    )}
                    {section['video'] && (
                      <div className="w-full flex flex-col justify-center items-center">
                        {section['video'].map((videoId, i) => (
                          <YoutubePlayer embedId={videoId} key={i} />
                        ))}
                      </div>
                    )}
                    {section['list'] && (
                      <ul className="font-goudy-old-style">
                        {section['list'].map((item, index) => (
                          <li className="relative m-2" key={index}>
                            <div className="absolute inset-0">{item}</div>
                            <div className="w-14 h-12 bg-gray rounded-xl"></div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {section?.ctaButtons &&
                      section?.ctaButtons.map((button) => (
                        <Button
                          primary={button.isPrimary}
                          isDisabled={button.isDisabled}
                        >
                          <MailToLink
                            email={button.email}
                            subject={button.subject}
                            body={button.body}
                            linkText={button.buttonText}
                          />
                        </Button>
                      ))}
                  </ContentBlock>
                </div>
              )
            })}
          <div className="w-12 h-12 fixed right-4 bottom-6">
            <Button
              className="w-full flex items-center justify-center"
              onClick={() => toggleModal()}
            >
              <Image
                src={'/images/Icon-Close.svg'}
                alt={''}
                height="30"
                width="30"
              />
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default UseMeView
