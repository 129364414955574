import React, { FC } from 'react'
import { useAppSelector } from 'store/reduxHooks'
import { selectProductData } from 'store/productSlice'
import { selectUser } from 'store/userSlice'

interface Props {
  body: string
  email: string
  linkText: string
  subject: string
}

const MailToLink: FC<Props> = ({ email, subject, body, linkText }) => {
  const { name } = useAppSelector(selectProductData)
  const { userName } = useAppSelector(selectUser)

  const formattedBody = body
    .replace('{{GARMENT}}', name)
    .replace('{{NAME}}', userName)

  return (
    <a
      href={`mailto:${email}?subject=${subject}&body=${formattedBody}`}
      target="_blank"
    >
      {linkText}
    </a>
  )
}

export default MailToLink
