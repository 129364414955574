import React, { FC } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  name: string;
}

const ProductTitle: FC<Props> = ({ className, name }) => {
  return (
    <p
      className={cn(
        ['text-center text-13'],
        className
      )}
    >
      {name}
    </p>
  );
};

export default ProductTitle;
