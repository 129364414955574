import React, { FC } from 'react'
import StoryBubble from 'components/storyBubble'
import AnimatedImage from './animatedImage'
import cn from 'classnames'
import { Story } from 'store/types'

interface Props {
  story: Story
  index: number
  viewportSize: string
}

const CarouselItem: FC<Props> = ({ story, index, viewportSize }) => {
  return (
    <div className="h-screen flex justify-center">
      {story.hasOwnProperty('paths') ? (
        <div
          className={cn(
            'flex', 'flex-col', 'w-full ', { 
              'h-315': viewportSize === 'small',
              'h-365': viewportSize !== 'small'
            }
          )}
        >
          {story.storyBubble && (
            <StoryBubble
              className={cn({
                'mt-0.5': viewportSize === 'small',
                'mt-5': viewportSize !== 'small'
              })}
            >
              {story.storyBubble}
            </StoryBubble>
          )}
          <div className="mt-auto w-full items-stretch">
            <AnimatedImage
              strokeWidth={0.5}
              paths={story.paths}
              orderOfItem={index}
              shouldAnimate={true}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-80 w-full">
          {story.storyBubble && (
            <StoryBubble className="mt-5">{story.storyBubble}</StoryBubble>
          )}
        </div>
      )}
    </div>
  )
}
export default CarouselItem
