import React, { FC, ReactNode, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import CarouselItem from './carouselItem'
import Pagination from './pagination'
import { selectViewport } from 'store/viewportSlice'
import { useAppSelector } from 'store/reduxHooks'
import { Stories, Story } from 'store/types'

interface Props {
  children?: ReactNode
  stories: Stories
  onFinishedCarousel?: () => void
  notFinishedCarousel: () => void
  onLastSlide?: () => void
  setToLastSlide?: boolean
  extraPagination?: boolean
}

const CarouselComponent: FC<Props> = ({
  onLastSlide,
  onFinishedCarousel,
  notFinishedCarousel,
  stories,
  setToLastSlide,
  extraPagination
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [onLastSlideNow, setOnLastSlide] = useState(false)

  const viewportSize = useAppSelector(selectViewport)
  const updateCarousel = (index: number) => {
    setCarouselIndex(index)
    if (index === stories.length - 1) {
      onLastSlideNow && onFinishedCarousel()
      onLastSlide()
      setOnLastSlide(true)
    } else {
      notFinishedCarousel()
      setOnLastSlide(false)
    }
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-md flex-col items-center">
        <Carousel
          selectedItem={setToLastSlide && stories.length - 1}
          swipeScrollTolerance={100}
          showThumbs={false}
          swipeable={true}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={false}
          autoPlay={false}
          emulateTouch={true}
          onChange={updateCarousel}
        >
          {stories.length &&
            stories.map((story: Story, index) => (
              <CarouselItem
                viewportSize={viewportSize}
                key={index}
                index={index}
                story={story}
              />
            ))}
        </Carousel>
      </div>
      {!setToLastSlide && (
        <div className="absolute w-full top-76% flex justify-center">
          {stories?.length &&
            stories.map((story: Story, index) =>
              carouselIndex === index ? (
                <Pagination
                  key={index}
                  viewportSize={viewportSize}
                  active={true}
                />
              ) : (
                <Pagination
                  key={index}
                  viewportSize={viewportSize}
                  active={false}
                />
              )
            )}
          {extraPagination && (
            <Pagination viewportSize={viewportSize} active={false} />
          )}
        </div>
      )}
    </div>
  )
}
export default CarouselComponent
