import { FC } from 'react';

interface Props {
  className?: string;
}

const SeparatingLine: FC<Props> = ({ className }) => {
  return (
    <div className={`my-40 ${className}`}>
      <svg className='w-full h-px bg-gray bg-transparent stroke-current'></svg>
    </div>
  );
};

export default SeparatingLine;
