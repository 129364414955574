import { FC } from 'react';
import cn from 'classnames';
import ProductImage from '../image';
import { selectBackgroundState } from 'store/backgroundSlice';
import { useAppSelector } from 'store/reduxHooks';
import { selectProductData } from 'store/productSlice';
import { selectViewport } from 'store/viewportSlice';

const Background: FC = () => {
  const backgroundPosition = useAppSelector(selectBackgroundState);
  const viewportSize = useAppSelector(selectViewport);
  const { photograph } = useAppSelector(selectProductData);

  return (
    <div className="h-screen w-screen overflow-hidden absolute inset-0">
      <div className="w-full h-full">
        <div
          className={cn(
            'flex justify-center transition-all duration-700 ease-in-out absolute bg-all',
            {
              'bg-centered': backgroundPosition === 'centered',
            },
            {
              'bg-centered-higher': backgroundPosition === 'centered-higher',
            },
            {
              'bg-centered-lower': backgroundPosition === 'centered-lower',
            },
            {
              'bg-centered-lower-small': backgroundPosition === 'centered-lower-small',
            },
            {
              'bg-bottom': backgroundPosition === 'bottom',
            },
            {
              'bg-bottom-lower': backgroundPosition === 'bottom-lower',
            }
          )}
        >
          {photograph && (
            <ProductImage
            className=
            {cn(viewportSize == 'small' ? ['w-48 h-full'] : ['w-full h-full']
            )}
              name="Mono Air Houdi"
              image={photograph}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Background;
