import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  primary?: boolean;
  secondary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  formaction?: string;
}

const Button: FC<Props> = ({
  className,
  primary = false,
  secondary = false,
  isDisabled = false,
  onClick,
  formaction,
  children,
}) => {
  return (
    <button 
    disabled={isDisabled}
      className={cn(
        [
          'w-full',
          'h-44',
          'rounded',
          'my-0.6',
          'cursor-pointer',
          'text-14'
        ],
        primary ? ['bg-black', 'text-white'] : secondary? ['bg-white', 'text-black'] : ['bg-gray', 'text-black'],
        isDisabled ? ['opacity-30'] : ['hover:opacity-80 focus:opacity-80'],
        className
      )}
      onClick={onClick}
      formAction={formaction}
    >
      {children}
      {isDisabled && ' (coming feature)'}
    </button>
  );
};

export default Button;
