import React, { FC, useState } from 'react'
import Input from './input'
import Button from './layout/button'
import cn from 'classnames'

interface Props {
  className?: string
  currentName: string
  hasProvidedLegalName: boolean
  textConnectView: string[]
  onClick: (localUserName: string) => void
}

const InputUserName: FC<Props> = ({
  currentName,
  hasProvidedLegalName,
  textConnectView,
  onClick
}) => {
  const [localUserName, setLocalUserName] = useState(currentName)

  return (
    <div className="w-full flex flex-col items-center">
      <Input
        className={cn({
          'text-red border-red': !hasProvidedLegalName && currentName,
          'text-green border-green': hasProvidedLegalName && currentName,
          'text-black border-black': hasProvidedLegalName && !currentName
        })}
        onChange={(e) => setLocalUserName(e.target.value)}
        placeholder="Name..."
        value={localUserName}
      />

      <Button
        className="max-w-xs"
        primary
        onClick={() => localUserName && onClick(localUserName)}
      >
        {hasProvidedLegalName
          ? textConnectView['ButtonResponseOKName']
          : textConnectView['ButtonResponseMyName']}
      </Button>
    </div>
  )
}

export default InputUserName
