import React, { FC, ReactNode, useRef } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

interface Props {
  isOpen: boolean;
  isTransparent?: boolean;
  isClosable?: boolean;
  onClose: () => void;
  className?: string;
  children: ReactNode;
  isFullscreen: boolean;
}

export const Modal: FC<Props> = ({
  onClose,
  children,
  isTransparent = true,
  isClosable = true,
  isFullscreen,
}) => {
  const outsideRef = useRef(null);

  const handleCloseOnOverlay = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (e.target === outsideRef.current && isClosable) {
      onClose();
    }
  };

  return (
    <div
      ref={outsideRef}
      className={cn([
        'absolute top-0 left-0 flex flex-col h-screen w-screen cursor-pointer',
      ])}
      onClick={handleCloseOnOverlay}
    >
      <motion.div
        className={cn(
          [
            'p-6 pb-20 mt-12 rounded-t-3xl w-full cursor-auto overflow-scroll bg-white',
          ],
          { 'opacity-80 backdrop-filter backdrop-blur-md': isTransparent },
          { 'fixed bottom-0': !isFullscreen }
        )}
        initial={{ y: 700 }}
        animate={{
          y: 0,
          transition: { duration: 0.5 },
        }}
        exit={{
          y: 700,
          transition: { duration: 0.5 },
        }}
      >
        <div className='justify-center'>{children}</div>
      </motion.div>
    </div>
  );
};

export default Modal;
