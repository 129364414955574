import React, { FC } from 'react'
import Image from 'next/image'
import cn from 'classnames'

interface CheckboxProps {
  isSelected: boolean
  onClick: () => void
  className?: string
}

const Checkbox: FC<CheckboxProps> = ({
  isSelected = false,
  onClick,
  className
}) => (
  <div
    onClick={onClick}
    className={cn(
      'mr-3',
      'border',
      'border-solid',
      'w-5',
      'h-5',
      'flex',
      'items-center',
      'justify-center',
      className
    )}
  >
    {isSelected && (
      <Image src={'/images/Icon-Checked.svg'} alt={''} height="24" width="24" />
    )}
  </div>
)

export default Checkbox
