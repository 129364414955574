import React, { FC } from 'react'
import cn from 'classnames'

interface SizeContainerProps {
  className: string
  size: string
}

const SizeContainer: FC<SizeContainerProps> = ({ className, size }) => (
  <div
    className={cn(
      'leading-9',
      'text-center',
      'font-thin',
      'text-13',
      'px-2',
      className
    )}
  >
    Size <span className="font-normal">{size}</span>
  </div>
)

export default SizeContainer
