import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { storyBubbleAnimation } from '../public/framerMotionVariants'
import { useAppSelector } from 'store/reduxHooks'
import { selectViewport } from 'store/viewportSlice'

interface StoryBubbleProps {
  children: ReactNode
  className?: string
  smallBubble?: boolean
}

const StoryBubble: FC<StoryBubbleProps> = ({
  children,
  className,
  smallBubble = false
}) => {
  const viewportSize = useAppSelector(selectViewport)

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={storyBubbleAnimation}
      >
        <div className="flex justify-center w-full px-8">
          <div
            className={cn(
              'relative',
              'max-w-xs',
              'font-goudy-old-style',
              'my-3',
              'bg-white',
              'text-center',
              'leading-21',
              'min-h-16.5 min-w-44',
              'break-words',
              className,
              {
                'rounded py-3 px-3 text-18': !smallBubble,
                'rounded-full py-0.5 px-3 text-12 mx-2': smallBubble,
                'mx-0': viewportSize === 'small',
                'mx-2': viewportSize !== 'small'
              }
            )}
          >
            <p>{children}</p>
            <div
              className={cn(
                'absolute',
                'l-1/2',
                'w-1/2',
                'bg-white',
                { 'h-5 -bottom-5': !smallBubble },
                { 'h-3 -bottom-3': smallBubble }
              )}
            />
            <div
              className={cn(
                'absolute',
                'right-0',
                'w-1/2',
                'rounded-tl-2xl',
                'bg-gray',
                {
                  'h-5 -bottom-5': !smallBubble,
                  'h-3 -bottom-3': smallBubble
                }
              )}
            />
            <div
              className={cn(
                'absolute',
                'left-0',
                'w-1/2',
                'rounded-tr-2xl',
                'bg-gray',
                {
                  'h-5 -bottom-5': !smallBubble,
                  'h-3 -bottom-3': smallBubble
                }
              )}
            />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default StoryBubble
