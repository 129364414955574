import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

const H2: FC<Props> = ({ className, children }) => {
  return <h2 className={cn(['text-20'], className)}>{children}</h2>;
};

export default H2;
