import { FC, useState } from 'react'
import cn from 'classnames'
import Button from './layout/button'
import { setErrorMessage, selectError } from 'store/errorSlice'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import Paragraph from './layout/paragraph'
import H2 from './layout/h2'

interface Props {
  className?: string
}

const Banner: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch()
  const [isBannerOpen, setIsBannerOpen] = useState(true)
  const errorMessage = useAppSelector(selectError)

  const toggleBanner = () => {
    dispatch(setErrorMessage(null))
    setIsBannerOpen(!isBannerOpen)
  }

  return (
    isBannerOpen && (
      <div className="absolute w-full top-2 left-0 p-2 z-20">
        <div
          className={cn(
            'relative',
            'flex',
            'flex-col',
            'w-full',
            'bg-white',
            'rounded-3xl',
            'leading-snug',
            'text-center',
            'items-center',
            'p-2',
            className
          )}
        >
          <div className="flex items-center justify-center w-12 h-12 border border-red bg-white text-red text-20 rounded-full my-4">
            !
          </div>

          <H2>{errorMessage.title}</H2>

          <Paragraph className="font-goudy-old-style px-10 py-2">
            {errorMessage.message}
          </Paragraph>

          <div className="w-full my-4 px-2">
            <Button primary onClick={() => toggleBanner()}>
              Ok
            </Button>
          </div>
        </div>
      </div>
    )
  )
}

export default Banner
