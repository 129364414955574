// Story bubbles

export const storyBubbleAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: [0, 1],
    transition: {
      delay: 0.4,
      duration: 0.6,
    },
    translateY: [10, 0],
  },
  exit: {
    opacity: [1, 0],
    transition: {
      delay: 0.2,
      duration: 0.6,
    },
    translateY: [0, 10],
  },
};

// Input

export const inputAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: [0, 1],
    transition: {
      delay: 0.4,
    },
    translateY: [30, 0],
  },
  exit: {
    opacity: 0,
  },
};

// Skip button

export const skipButtonAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
    },
    translateX: [-120, 0],
  },
};

// Intro View

export const introTranslateY = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    translateY: [10, 0],
  },
};

// Loading View

export const loadingViewAnimation = {
  hidden: {
    scale: 0.8,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.4,
    },
  },
};

// Connected View

export const translateYdirNeg = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.4,
    },
    translateY: [-10, 0],
  },
};

export const translateXdirPos = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.4,
    },
    translateX: [10, 0],
  },
};

export const translateYdirPos = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.4,
    },
    translateY: [10, 0],
  },
};

export const animationVariantOpacity = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const wordOfWisdom = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: [0.5, 1],
    transition: {
      delay: 0.2,
    },
    translateY: [20, 0],
  },
  exit: {
    opacity: [1, 0],
    translateY: [0, 10],
  },
};

// Not Connected View

export const hiAnimation = {
  exit: {
    opacity: [1, 0],
    translateY: [0, 10],
    transition: {
      duration: 0.6,
    },
  },
};

export const transYVariants = {
  hidden: {
    scale: 1,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.4,
    },
    translateY: [10, 0],
  },
  exit: {
    opacity: [1, 0.2],
    translateY: [0, 40],
    transition: {
      duration: 0.2,
    },
  },
};

// Tap View

export const tapViewAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: [0, 1],
    transition: {
      delay: 0.4,
      duration: 0.6,
    },
    translateY: [10, 0],
  },
};

// Adventure View

export const adventureAnimation = {
  hidden: {
    opacity: 1,
  },
  visible: {
    translateY: [40, 0],
  },
  exit: {
    opacity: 0.5,
    translateY: [0, 30],
  },
};

export const adventureAnimationButton = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
    translateY: [10, 0],
  },
};
