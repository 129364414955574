import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

const H1: FC<Props> = ({ className, children }) => {
  return <h1 className={cn(['text-26'], className)}>{children}</h1>;
};

export default H1;
