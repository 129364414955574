import firebase from '../utils/firebase'
import { Adventure } from 'store/types'
import jsonData from '../public/data.json'
const db = firebase.firestore()

export const saveUserConnection = async (
  userName: string,
  email: string,
  productId: string,
  connected: boolean,
  adventures: Adventure[]
) => {
  const adventuresToSave = adventures.map((adventure) => ({
    advID: adventure.advID,
    date: adventure.date
  }))

  return await db.collection('connected').doc(productId).set({
    userName: userName,
    email: email,
    connected: connected,
    adventures: adventuresToSave
  })
}

export const updateUserConnection = async (productId: string) => {
  return await db.collection('connected').doc(productId).set({
    connected: false
  })
}

export const updateUserAdventure = async (
  productId: string,
  adventures: Adventure[]
) => {
  const adventuresToSave = adventures.map((adventure) => ({
    advID: adventure.advID,
    date: adventure.date
  }))

  return await db
    .collection('connected')
    .doc(productId)
    .set(
      {
        adventures: adventuresToSave
      },
      { merge: true }
    )
    .catch((error) => {
      console.log('Error setting document:', error)
    })
}

export const getConnectedProduct = async (productId: string) => {
  const docRef = db.collection('connected').doc(productId)
  const adventuresList = jsonData.adventuresList

  return docRef
    .get()
    .then((doc) => doc.data())
    .then((data) =>
      data.adventures
        ? {
            ...data,
            adventures: data.adventures?.map((activity) => ({
              ...activity,
              ...adventuresList.find(
                (element) => element.advID === activity.advID
              )
            }))
          }
        : data
    )
    .catch((error) => {
      console.log('Error getting document:', error)
    })
}
