import { FC } from 'react'
import { LogInSteps } from './connectView'

type StoryBubbleTextProp = {
  modalContent: LogInSteps
  localUserName: string
  localEmail: string
  connectView: string[]
}

export const StoryBubbleText: FC<StoryBubbleTextProp> = ({
  modalContent,
  localUserName,
  localEmail,
  connectView
}) => {
  if (!modalContent.hasProvidedLegalUsername && !localUserName) {
    return (
      <>
        {connectView['IntroPhrase']}
        <br />
        {connectView['AskName']}
      </>
    )
  }

  if (
    !modalContent.hasProvidedLegalEmail &&
    modalContent.hasProvidedLegalUsername &&
    !localEmail
  ) {
    return <>{connectView['AskForEmail1']}{localUserName}{connectView['AskForEmail2']}</>
  }

  if (
    (!modalContent.hasProvidedLegalEmail && localEmail) ||
    (!modalContent.hasProvidedLegalUsername && localUserName)
  ) {
    return <>{connectView['SpellChecker']}</>
  }

  return <>{connectView['ErrorForgotPrivacy']}</>
}
