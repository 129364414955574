import React, { FC, useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { selectProductData } from 'store/productSlice'
import { selectContent } from 'store/contentSlice'
import { selectUser } from 'store/userSlice'
import { setCentered } from 'store/backgroundSlice'
import { selectActiveAdventure } from 'store/exploreSlice'

import ProductTitle from '../layout/productTitle'
import Navbar from 'components/navbar'
import StoryBubble from 'components/storyBubble'
import NameTitle from 'components/nameTitle'
import BodyText from 'components/layout/bodyText'
import LifeCircle from 'components/circleOfLife/lifeCircle'
import Button from 'components/layout/button'
import FirstConnectAnimatedImage from 'components/carousel/firstConnectAnimatedImage'
import CareRepairModalView from './NavBarViews/careRepairInModal'
import CoCreateView from './NavBarViews/coCreateView'
import UseMeView from './NavBarViews/useMeView'
import AdventureView from './NavBarViews/adventureView'
import {
  translateYdirNeg,
  translateXdirPos,
  translateYdirPos,
  animationVariantOpacity,
  skipButtonAnimation,
  wordOfWisdom
} from 'public/framerMotionVariants'
import useScrollToTop from '../../hooks/useScrollToTop'
import Section from 'components/layout/section'

const ConnectedView: FC = () => {
  const dispatch = useAppDispatch()
  const productData = useAppSelector(selectProductData)
  const userData = useAppSelector(selectUser)
  const text = useAppSelector(selectContent)
  const activeAdventure = useAppSelector(selectActiveAdventure)
  const adventures = useAppSelector(selectUser).adventures
  const [adventuresUpdated, setAdventuresUpdated] = useState(false)

  const [showAdventureView, setAdventureView] = useState(false)
  const [showCareRepairView, setCareRepairView] = useState(false)
  const [showCoCreateView, setCoCreateView] = useState(false)
  const [showUseMeView, setUseMeView] = useState(false)

  const [showInspirationAdventure, setShowInspirationAdventure] =
    useState(false)
  const [pressedSkip, setPressedSkip] = useState(false)

  const numberOfAdventures = userData.adventures
    ? userData.adventures.length
    : 0

  let timer: NodeJS.Timeout

  useScrollToTop()

  useEffect(() => {
    setAdventuresUpdated(true)
  }, [adventures])

  useEffect(() => {
    if (showInspirationAdventure && !pressedSkip) {
      timer = setTimeout(
        () => (setShowInspirationAdventure(false), dispatch(setCentered())),
        6000
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [showInspirationAdventure])

  const skipInspoAdventure = () => {
    setPressedSkip(true)
    setShowInspirationAdventure(false)
    dispatch(setCentered())
  }

  return (
    <div className="flex flex-col w-full h-full">
      <Section className="pt-5">
        <AnimatePresence>
          <motion.div
            key="prodName"
            initial="hidden"
            animate="visible"
            variants={translateYdirNeg}
          >
            <ProductTitle
              className="mb-3"
              name={productData?.name}
            ></ProductTitle>
          </motion.div>
          {!showInspirationAdventure ? (
            <>
              {activeAdventure && (
                <StoryBubble smallBubble>{activeAdventure?.text}</StoryBubble>
              )}
              <div className="flex justify-center h-96">
                <LifeCircle clickableAdventure animate={adventuresUpdated} />
              </div>
            </>
          ) : (
            <motion.div
              key="addedAdv"
              variants={translateXdirPos}
              initial="hidden"
              animate="visible"
            >
              <>
                {activeAdventure && (
                  <StoryBubble smallBubble>{activeAdventure?.text}</StoryBubble>
                )}

                <div className="w-full justify-center items-center flex text-center">
                  <FirstConnectAnimatedImage
                    className="relative h-43 w-auto mt-10"
                    paths={activeAdventure?.paths}
                    strokeWidth={0.5}
                    shouldAnimate={false}
                  />
                </div>
              </>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {!showInspirationAdventure ? (
            <motion.div
              className={cn(['w-full fixed left-0 bottom-0 pb-20'])}
              key="nrAdventures"
              variants={translateYdirPos}
              initial="hidden"
              animate="visible"
            >
              <NameTitle className="text-center max-w-full break-words">
                {text.connectedView[1]} {userData.userName}
              </NameTitle>
              <BodyText className="mb-4 text-14">
                {text.connectedView[2]}
                {numberOfAdventures}{' '}
                {numberOfAdventures === 1
                  ? 'adventure together'
                  : text.connectedView[3]}
              </BodyText>
            </motion.div>
          ) : (
            <div>
              <motion.div
                className="w-full mt-14"
                key="wisdomWords"
                variants={wordOfWisdom}
                initial="hidden"
                animate="visible"
              >
                <BodyText className="mb-2 mx-10 text-center text-16">
                  {activeAdventure?.inspoText}
                </BodyText>
                <BodyText className="mb-2 text-12">
                  Houdini design team
                </BodyText>
              </motion.div>

              <div className="fixed bottom-6 right-4 pb-2">
                <div className="w-20 h-12">
                  <motion.div
                    key="connectedSkip"
                    initial="hidden"
                    animate="visible"
                    variants={skipButtonAnimation}
                  >
                    <Button
                      primary={false}
                      secondary
                      onClick={() => skipInspoAdventure()}
                    >
                      {text.buttonSkip}
                    </Button>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {showAdventureView ? (
            <motion.div
              key="adView"
              variants={animationVariantOpacity}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <AdventureView
                inspirationAdventure={setShowInspirationAdventure}
                closeAdventureView={() => setAdventureView(false)}
              />
            </motion.div>
          ) : showUseMeView ? (
            <motion.div
              key="useView"
              variants={animationVariantOpacity}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <UseMeView closeUseMeView={() => setUseMeView(false)} />
            </motion.div>
          ) : showCareRepairView ? (
            <motion.div
              key="careView"
              variants={animationVariantOpacity}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <CareRepairModalView
                closeCareRepairView={() => setCareRepairView(false)}
              />
            </motion.div>
          ) : showCoCreateView ? (
            <motion.div
              key="coView"
              variants={animationVariantOpacity}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <CoCreateView closeCoCreateView={() => setCoCreateView(false)} />
            </motion.div>
          ) : !showInspirationAdventure ? (
            <motion.div
              key="navBar"
              variants={animationVariantOpacity}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Navbar
                openAdventureView={() => setAdventureView(true)}
                openCareRepairView={() => setCareRepairView(true)}
                openCoCreateView={() => setCoCreateView(true)}
                openUseMeView={() => setUseMeView(true)}
              ></Navbar>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Section>
    </div>
  )
}

export default ConnectedView
