import React, { FC, useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ProductTitle from '../layout/productTitle'
import SizeContainer from '../sizeContainer'
import ColorCircle from '../colorCircle'
import Button from '../layout/button'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import StoryBubble from 'components/storyBubble'
import { setBottom, setCentered } from 'store/backgroundSlice'
import { selectProductData } from 'store/productSlice'
import { setActiveView, View } from 'store/viewSlice'
import { selectContent } from 'store/contentSlice'
import {
  hiAnimation,
  translateYdirNeg,
  transYVariants
} from 'public/framerMotionVariants'
import useScrollToTop from '../../hooks/useScrollToTop'
import Section from 'components/layout/section'

const NotConnectedStart: FC = ({}) => {
  const dispatch = useAppDispatch()
  const productData = useAppSelector(selectProductData)
  const text = useAppSelector(selectContent)

  const [isConfirmed, setIsConfirmed] = useState(false)
  const [sayHi, setSayHi] = useState(true)

  useScrollToTop()

  const goToLearnMoreView = () => {
    dispatch(setActiveView(View.INTRO))
    dispatch(setBottom())
  }

  const goToConnectView = () => {
    dispatch(setActiveView(View.CONNECT))
    dispatch(setCentered())
  }

  const onHi = () => {
    setSayHi(false)
  }

  useEffect(() => {
    const introTimeout = setTimeout(onHi, 2000)
    return () => {
      clearTimeout(introTimeout)
    }
  }, [sayHi])

  return (
    <div className="flex flex-col justify-between content-between w-full h-full place-content-between">
      <AnimatePresence exitBeforeEnter>
        {sayHi ? (
          <motion.div key="hi" exit="exit" variants={hiAnimation}>
            <StoryBubble key="notCon0" className="mt-24">
              {text.notConnectedView[0]}
            </StoryBubble>
          </motion.div>
        ) : (
          <>
            <Section className="mt-5">
              <motion.div
                key="productName"
                variants={translateYdirNeg}
                initial="hidden"
                animate="visible"
              >
                <ProductTitle
                  className="mb-3"
                  name={productData?.name}
                />
              </motion.div>
              {!isConfirmed ? (
                <StoryBubble key="notCon1">
                  {text.notConnectedView[1]}
                </StoryBubble>
              ) : (
                <StoryBubble key="notCon2">
                  {text.notConnectedView[2]}
                </StoryBubble>
              )}
            </Section>
            <Section className="w-full fixed bottom-0 flex-col justify-center mb-1">
              {!isConfirmed ? (
                <motion.div
                  key="product"
                  initial="hidden"
                  animate="visible"
                  variants={transYVariants}
                >
                  <div className="py-2 flex items-center justify-center">
                    <SizeContainer
                      size={productData && productData.size}
                      className="bg-white mr-2"
                    ></SizeContainer>
                    <ColorCircle
                      colorName={productData && productData.colorName}
                    ></ColorCircle>
                  </div>
                </motion.div>
              ) : (
                ''
              )}
              {!isConfirmed ? (
                <motion.div
                  key="button-confirm"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={transYVariants}
                >
                  <div className="w-full flex flex-col items-center ">
                    <Button
                      primary
                      onClick={() => setIsConfirmed(!isConfirmed)}
                    >
                      {text.notConnectedView[3]}
                    </Button>
                    <Button secondary onClick={goToLearnMoreView}>
                      {text.notConnectedView[4]}
                    </Button>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="button-second-confirm"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={transYVariants}
                >
                  <div className="w-full flex flex-col items-center ">
                    <Button primary onClick={goToConnectView}>
                      {text.notConnectedView[5]}
                    </Button>
                    <Button secondary onClick={goToLearnMoreView}>
                      {text.notConnectedView[6]}
                    </Button>
                  </div>
                </motion.div>
              )}
            </Section>
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default NotConnectedStart
