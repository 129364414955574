import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

interface Props {
  className?: string
  name?: string
  children: ReactNode
}

const NameTitle: FC<Props> = ({ className, children, name }) => (
  <div className="flex justify-center w-full">
    <h1 className={cn('text-2xl', 'my-0.5', className)}>
      {children}
      {name}
    </h1>
  </div>
)

export default NameTitle
