import React, { FC } from 'react'
import AnimatedImage from 'components/carousel/animatedImage'
import { setActiveView, View } from 'store/viewSlice'
import { useDispatch } from 'react-redux'
import { setActiveAdventure } from 'store/exploreSlice'
import { Adventure } from 'store/types'
import { motion } from 'framer-motion'

interface Props {
  diameter: number
  clickableAdventure: boolean
  shouldAnimate?: boolean
  degrees: number
  adventures: Adventure[]
}

const TiltedAdventures: FC<Props> = ({
  clickableAdventure,
  shouldAnimate = false,
  diameter,
  degrees,
  adventures
}) => {
  const dispatch = useDispatch()

  const imageW = 120 // DO NOT CHANGE
  const imageH = 42
  const innerRadius = diameter / 2 - imageH

  function sinDegrees(angleDegrees: number) {
    return Math.sin((angleDegrees * Math.PI) / 180)
  }

  function cosDegrees(angleDegrees: number) {
    return Math.cos((angleDegrees * Math.PI) / 180)
  }

  const getTranslateY = (index: number) => {
    return cosDegrees(getRotationDeg(index)) * innerRadius
  }

  const getTranslateX = (index: number) => {
    return sinDegrees(getRotationDeg(index)) * innerRadius
  }

  const getRotationDeg = (index: number) => {
    return index * degrees
  }

  const goToExploreView = (addedActivity: Adventure) => {
    dispatch(setActiveAdventure(addedActivity))
    dispatch(setActiveView(View.EXPLORE))
  }

  return (
    <div
      className="flex absolute left-1/2 -ml-60 w-screen"
      style={{ bottom: diameter / 2 - 21 }}
    >
      <div className="flex w-1/2 h-43">
        {adventures.map((adventure: Adventure, index: number) => {
          return (
            <motion.div
              className="absolute transform"
              onClick={() =>
                clickableAdventure ? goToExploreView(adventure) : null
              }
              key={index}
              style={{
                rotate: getRotationDeg(index),
                translateX: getTranslateX(index),
                translateY: -getTranslateY(index),
                width: imageW,
                height: imageH
              }}
            >
              <AnimatedImage
                strokeWidth={0.5}
                paths={adventure.paths}
                orderOfItem={index}
                shouldAnimate={shouldAnimate && index === adventures.length - 1}
              />
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}

export default TiltedAdventures
