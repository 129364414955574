import React, { FC, useState } from 'react'
import Input from './input'
import Button from './layout/button'
import cn from 'classnames'

interface InputEmailProps {
  className?: string
  hasProvidedLegalEmail: boolean
  currentEmail: string
  textConnectView: Array<string>
  onClick: (localEmail: string) => void
}

const InputEmail: FC<InputEmailProps> = ({
  hasProvidedLegalEmail,
  currentEmail,
  textConnectView,
  onClick
}) => {
  const [localEmail, setLocalEmail] = useState(currentEmail)

  return (
    <div className="w-full flex flex-col items-center">
      <Input
        className={cn({
          'text-red border-red': !hasProvidedLegalEmail && currentEmail,
          'text-green border-green': hasProvidedLegalEmail && currentEmail,
          'text-black border-black': hasProvidedLegalEmail && !currentEmail
        })}
        onChange={(e) => setLocalEmail(e.target.value)}
        placeholder={'E-mail...'}
        value={localEmail}
      />

      <Button
        className="max-w-xs"
        primary
        onClick={() => localEmail && onClick(localEmail)}
      >
        {hasProvidedLegalEmail
          ? textConnectView['ButtonResponseOKEmail']
          : textConnectView['ButtonResponseMyEmail']}
      </Button>
    </div>
  )
}

export default InputEmail
