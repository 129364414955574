import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import H3 from './h3';

interface Props {
  className?: string;
  heading?: string;
  children: ReactNode;
}

const TextBlock: FC<Props> = ({ className, heading, children }) => {
  return (
    <div className={cn(['mb-30'], className)}>
      {heading && <H3 className='mb-1'>{heading}</H3>}
      {children}
    </div>
  );
};

export default TextBlock;
