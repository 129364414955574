import { FC, ReactNode } from 'react';
import cn from 'classnames';


interface Props {
  className?: string;
  children: ReactNode;
}

const H3: FC<Props> = ({ className, children }) => {
  return <h3 className={cn(['text-13 font-medium mt-30'], className)}>{children}</h3>;
};

export default H3;
