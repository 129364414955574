import Button from 'components/layout/button'
import React, { FC, useState } from 'react'
import Image from 'next/image'
import Modal from 'components/modal/modal'
import ToggleButton from 'components/toggleButton'
import { useAppSelector } from 'store/reduxHooks'
import { selectContent } from 'store/contentSlice'
import Paragraph from 'components/layout/paragraph'

interface Props {
  setShowPolicy?: (t: boolean) => void
}

const DataPolicyView: FC<Props> = ({ setShowPolicy: setWillShowPolicy }) => {
  const text = useAppSelector(selectContent)

  const [isModalOpen, setModalState] = useState(true)
  const toggleModal = () => {
    setModalState(!isModalOpen)
  }

  const [toggleButtonShare, settoggleButtonShare] = useState(true)
  const [toggleButtonRecieve, settoggleButtonRecieve] = useState(false)
  const [toggleButtonNews, setToggleButtonNews] = useState(false)
  const [toggleButtonScience, setToggleButtonScience] = useState(false)

  return (
    <>
      {isModalOpen ? (
        <div>
          <Modal
            isFullscreen={true}
            isClosable={false}
            isTransparent={false}
            isOpen={isModalOpen}
            onClose={toggleModal}
          >
            <div className="text-center items-center">
              <h1>{text.dataPolicy[0]}</h1>
              <Paragraph className="py-4 px-2 mb-6">
                {text.dataPolicy[1]}
              </Paragraph>
              <h1>{text.dataPolicy[2]}</h1>
              <Paragraph className="py-4 px-2 mb-6">
                {text.dataPolicy[3]}
              </Paragraph>
              <h1>{text.dataPolicy[4]}</h1>
              <Paragraph className="py-4 px-2 mb-6">
                {text.dataPolicy[5]}
              </Paragraph>
              <div className="mb-6 text-left">
                <div className="w-full flex justify-between items-center">
                  <Paragraph className="py-2">{text.dataPolicy[6]}</Paragraph>
                  <ToggleButton
                    onClick={() => settoggleButtonShare(!toggleButtonShare)}
                    primary={toggleButtonShare}
                  />
                </div>
                <div className="w-full flex justify-between items-center">
                  <Paragraph className="py-2">{text.dataPolicy[7]}</Paragraph>
                  <ToggleButton
                    onClick={() => settoggleButtonRecieve(!toggleButtonRecieve)}
                    primary={toggleButtonRecieve}
                  />
                </div>
                <div className="w-full flex justify-between items-center">
                  <Paragraph className="py-2">{text.dataPolicy[8]}</Paragraph>
                  <ToggleButton
                    onClick={() => setToggleButtonNews(!toggleButtonNews)}
                    primary={toggleButtonNews}
                  />
                </div>
                <div className="w-full flex justify-between items-center">
                  <Paragraph className="py-2">{text.dataPolicy[9]}</Paragraph>
                  <ToggleButton
                    onClick={() => setToggleButtonScience(!toggleButtonScience)}
                    primary={toggleButtonScience}
                  />
                </div>
              </div>
              <h1>{text.dataPolicy[10]}</h1>
              <Paragraph className="py-4 px-2 mb-8">
                {text.dataPolicy[11]}
              </Paragraph>
              <div className="flex justify-center relative bottom-3 pb-2">
                <div className="flex-grow max-w-xs h-12 ">
                  <Button primary onClick={() => setWillShowPolicy(false)}>
                    {text.dataPolicy[12]}
                  </Button>
                </div>
                <div className="w-12 h-12 ml-1">
                  <Button
                    className="w-full flex items-center justify-center"
                    onClick={() => setWillShowPolicy(false)}
                  >
                    <Image
                      src={'/images/Icon-Close.svg'}
                      alt={''}
                      height="30"
                      width="30"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default DataPolicyView
