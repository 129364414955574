import React, { FC } from 'react'
import cn from 'classnames'

interface InputProps {
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
}

const Input: FC<InputProps> = ({ className, onChange, placeholder, value }) => {
  return (
    <form className="w-full text-center">
      <input
        className={cn(
          [
            'w-full',
            'h-44',
            'rounded',
            'my-0.6',
            'bg-transparent',
            'text-black',
            'border',
            'border-black',
            'p-2',
            'max-w-xs',
            'text-16'
          ],
          className
        )}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </form>
  )
}

export default Input
