import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

const Paragraph: FC<Props> = ({ className, children }) => {
  return (
    <p className={cn(['font-goudy-old-style leading-19'], className)}>
      {children}
    </p>
  );
};

export default Paragraph;
