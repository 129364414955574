import React, { FC, useEffect } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import Layout from 'components/layout/layout';
import { loadingViewAnimation } from 'public/framerMotionVariants';

const LoadingView: FC<{ setIsLoadingAnimationDone: (e: boolean) => void }> = ({
  setIsLoadingAnimationDone,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingAnimationDone(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Layout>
      <div className='bg-gray h-screen flex relative items-center text-black justify-center p-6'>
        <div className='-mt-52'>
          <motion.div
            initial='hidden'
            animate='visible'
            variants={loadingViewAnimation}
          >
            <Image src={'/images/Logo.svg'} alt={''} height='19' width='100' />
          </motion.div>
        </div>
        <motion.svg
          initial='hidden'
          animate='visible'
          className='absolute'
          strokeWidth='1'
          width='60'
          height='60'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.path
            stroke='black'
            variants={{
              hidden: {
                pathLength: 0,
                rotate: -90,
              },
              visible: {
                pathLength: 1,

                transition: {
                  delay: 0.4,
                  duration: 2,
                },
              },
            }}
            d='M 97.5 50 C 97.5 76.8001 75.9955 98.5 49.5 98.5 C 23.0045 98.5 1.5 76.8001 1.5 50 C 1.5 23.1999 23.0045 1.5 49.5 1.5 C 75.9955 1.5 97.5 23.1999 97.5 50 Z'
          />
        </motion.svg>
      </div>
    </Layout>
  );
};
export default LoadingView;
