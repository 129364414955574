import { FC, useState } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import Button from '../layout/button';
import CarouselComponent from 'components/carousel/carousel';
import { setBottom, setCenteredLower, setCenteredLowerSmall } from 'store/backgroundSlice';
import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import { setActiveView, View } from 'store/viewSlice';
import { selectContent } from 'store/contentSlice';
import { introTranslateY, skipButtonAnimation } from 'public/framerMotionVariants';
import useScrollToTop from '../../hooks/useScrollToTop';
import { selectViewport } from 'store/viewportSlice';

interface Props {
  title: string;
}

const IntroView: FC<Props> = ({ title }) => {
  const [showLastSlide, setShowLastSlide] = useState(false);
  const dispatch = useAppDispatch();

  useScrollToTop();

  const viewportSize = useAppSelector(selectViewport);
  const introStories = useAppSelector(selectContent).introStories;

  const finishedCarousel = () => {
    //do nothing
  };

  const notFinishedCarousel = () => {
    setShowLastSlide(false);
    dispatch(setBottom());
  };

  const onLastSlide = () => {
    setShowLastSlide(true);
    viewportSize === 'small' 
    ? dispatch(setCenteredLowerSmall()) 
    : dispatch(setCenteredLower());
  };

  const goBack = () => {
    dispatch(setActiveView(View.NOT_CONNECTED_START));
  };

  return (
    <div className="text-center h-screen">
      <section className="mt-auto h-full">
        <div className="text-13 mt-5 pb-6">{title}</div>
        
          <CarouselComponent
            stories={introStories}
            onLastSlide={() => onLastSlide()}
            onFinishedCarousel={() => finishedCarousel()}
            notFinishedCarousel={() => notFinishedCarousel()}
            setToLastSlide={showLastSlide}
          ></CarouselComponent>
        <div className="fixed bottom-3 w-full">
          {(!showLastSlide) ? (
            <div className="flex relative justify-end bottom-3 mx-4 pb-2">
              <div className="w-20 h-12">
                <motion.div
                  key="intro"
                  initial="hidden"
                  animate="visible"
                  variants={skipButtonAnimation}
                >
                  <Button
                    primary={false}
                    secondary
                    onClick={() => setShowLastSlide(true)}
                  >
                    Skip
                  </Button>
                </motion.div>
              </div>
            </div>
          ) : (
            <motion.div
              className="flex justify-center relative bottom-3 mx-4 pb-2"
              initial="initial"
              animate="visible"
              variants={introTranslateY}
            >
              <div className="flex-grow max-w-xs h-12 mx-1">
                <Button
                  primary={true}
                  onClick={() => dispatch(setActiveView(View.CONNECT))}
                >
                  Yes
                </Button>
              </div>
              <div className="w-12 h-12 mx-1">
                <Button
                  className="w-full flex items-center justify-center"
                  primary={false}
                  secondary={true}
                  onClick={goBack}
                >
                  <Image
                    src={'/images/Icon-Close.svg'}
                    alt={''}
                    height="30"
                    width="30"
                  />
                </Button>
              </div>
            </motion.div>
          )}
        </div>
      </section>
    </div>
  );
};
export default IntroView;
