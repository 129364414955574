import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { NextPageContext } from 'next';

import Layout, { siteTitle } from '../components/layout/layout';
import TapAndConnectView from 'components/views/tapView';
import NotConnectedStart from '../components/views/notConnectedView';
import IntroView from '../components/views/introView';
import LoadingView from 'components/views/loadingView';
import ConnectView from 'components/views/connectView';
import ConnectedView from 'components/views/connectedView';
import CreationStoryView from 'components/views/creationStoryView';
import ExploreView from 'components/views/exploreView';
import Banner from 'components/errorBanner';
import { fetchProductData } from 'services/EONService';
import { getConnectedProduct } from 'services/firebaseService';
import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import { selectProductData, setProductData } from 'store/productSlice';
import { selectActiveView, setActiveView, View } from 'store/viewSlice';
import { setLargeViewport, setSmallViewport } from 'store/viewportSlice';
import { setContent } from 'store/contentSlice';
import {setActiveAdventure} from 'store/exploreSlice'
import {
  setUserName,
  setEmail,
  setConnected,
  setUnconnected,
  setAdventures,
} from 'store/userSlice';
import { selectError } from 'store/errorSlice';

export default function Home({ data }) {
  const dispatch = useAppDispatch();
  const productData = useAppSelector(selectProductData);
  const activeView = useAppSelector(selectActiveView);
  const productName = productData?.name ? productData?.name : '';
  const [isLoadingAnimationDone, setIsLoadingAnimationDone] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const errorMessage = useAppSelector(selectError);

  function getWindowDimensions() {
    const { outerHeight: height } = window;
    (height < 700) ?
      dispatch(setSmallViewport()) :
      dispatch(setLargeViewport())
  }

  useEffect(() => {
    if (data && isLoadingAnimationDone) {
      getWindowDimensions();
      dispatch(setProductData(data));
      data.userName && dispatch(setUserName(data.userName));
      data.email && dispatch(setEmail(data.email));
      data.connected ? dispatch(setConnected()) : dispatch(setUnconnected());
      data.adventures && dispatch(setAdventures(data.adventures)) && dispatch(setActiveAdventure(data.adventures[data.adventures.length - 1]));
      setIsPageLoading(false);    
    } else {
      setIsPageLoading(true);
    }
  }, [data, isLoadingAnimationDone]);

  useEffect(() => {
    productData && productData.connected
        ? dispatch(setActiveView(View.CONNECTED_START))
        : dispatch(setActiveView(View.NOT_CONNECTED_START));
  }, [isPageLoading]);


  const getJsonContent = () => {
    fetch('data.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        dispatch(setContent(data));
      });
  };

  useEffect(() => {
    getJsonContent();
  }, []);

  return (
    <Layout>
      <Head>
        <title>{siteTitle}</title>
      </Head>
      {errorMessage?.title && <Banner></Banner>}
      {isPageLoading ? (
        <LoadingView setIsLoadingAnimationDone={setIsLoadingAnimationDone} />
      ) : (
        <>
          {activeView === View.NOT_CONNECTED_START && <NotConnectedStart />}
          {activeView === View.CONNECTED_START && <ConnectedView />}
          {activeView === View.CONNECT && <ConnectView title={productName} />}
          {activeView === View.INTRO && <IntroView title={productName} />}
          {activeView === View.INTRO_CREATION && (
            <CreationStoryView title={productName} />
          )}
          {activeView === View.TAP_AND_CONNECT && (
            <TapAndConnectView title={productName} />
          )}
          {activeView === View.EXPLORE && <ExploreView title={productName} />}
        </>
      )}
    </Layout>
  );
}

export async function getServerSideProps({ query }: NextPageContext) {
  const eid = query.eid as string;
  const uniqueId = `${query.eid}-${query.serial}`;
  const eonProductData = await fetchProductData(eid ? eid : '2477');
  const firestoreProduct = await getConnectedProduct(uniqueId);
  return {
    props: {
      data: {
        ...eonProductData,
        ...firestoreProduct,
        productName: eonProductData?.name,
        colorName: eonProductData?.color_name,
        productId: uniqueId,
      },
    },
    notFound: !eonProductData,
  };
}
