import React, { FC } from 'react'
import Image from 'next/image'
import Button from './layout/button'
import Checkbox from './checkbox'
import cn from 'classnames'

interface ConsentDataPolicyContentProps {
  className?: string
  hasProvidedLegalPolicyConsent: boolean
  textConnectView: Array<string>
  checkboxSelected: boolean
  onChange: (event) => void
  onShowPolicy?: (event) => void
  onClose: () => void
}

const ConsentDataPolicyContent: FC<ConsentDataPolicyContentProps> = ({
  textConnectView,
  checkboxSelected,
  onClose,
  onChange,
  hasProvidedLegalPolicyConsent
}) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full max-w-xs ">
        <div className="w-full flex justify-between items-center">
          <div className="flex">
            <Checkbox
              onClick={() => {
                onChange('checkBox')
              }}
              isSelected={checkboxSelected}
              className={cn({
                'border-red':
                  !hasProvidedLegalPolicyConsent && checkboxSelected !== null,
                'border-black': hasProvidedLegalPolicyConsent
              })}
            />

            <div className="flex justify-center content-center text-13 leading-5">
              <p>{textConnectView['AcceptPrivacy']}&nbsp;&nbsp;</p>
              <p className="underline" onClick={() => onChange('showPolicy')}>
                {textConnectView['GoToPrivacy']}
              </p>
            </div>
          </div>

          <div className="w-12 h-12">
            <Button
              className="w-full flex items-center justify-center"
              onClick={onClose}
            >
              <Image
                src={'/images/Icon-Close.svg'}
                alt={''}
                height="30"
                width="30"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsentDataPolicyContent
