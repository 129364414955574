import React, { FC } from 'react'
import cn from 'classnames'

interface ToggleButtonProps {
  className?: string
  primary?: boolean
  onClick: () => void
}

const ToggleButton: FC<ToggleButtonProps> = ({
  className,
  primary = false,
  onClick
}) => {
  return (
    <div className="relative h-31 flex items-center" onClick={onClick}>
      <div
        className={cn(
          'bg-black',
          'w-60',
          'h-full',
          'rounded-full',
          {
            'opacity-100': primary,
            'opacity-20': !primary
          },
          className
        )}
      ></div>
      <div
        className={cn(
          'absolute',
          'bg-white',
          'w-25',
          'h-25',
          'rounded-full',
          {
            'right-0.3': primary,
            'left-0.3': !primary
          },
          className
        )}
      ></div>
    </div>
  )
}

export default ToggleButton
