import { FC, useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '../layout/button'
import CarouselComponent from 'components/carousel/carousel'
import {
  setCentered,
  setBottom,
  setCenteredLower,
  setCenteredLowerSmall
} from 'store/backgroundSlice'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { setActiveView, View } from 'store/viewSlice'
import { selectContent } from 'store/contentSlice'
import { skipButtonAnimation } from 'public/framerMotionVariants'
import useScrollToTop from '../../hooks/useScrollToTop'
import { selectViewport } from 'store/viewportSlice'
import { selectProductData } from 'store/productSlice'

interface Props {
  title: string
}

const CreationStoryView: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch()
  const { name } = useAppSelector(selectProductData)
  const [skipToLastSlide, setShowLastSlide] = useState(false)
  const creationStories = useAppSelector(selectContent).creationStories[name]
  const viewportSize = useAppSelector(selectViewport)

  useScrollToTop()

  useEffect(() => {
    viewportSize === 'small'
      ? dispatch(setCenteredLowerSmall())
      : dispatch(setCenteredLower())
  }, [])

  const finishedCarousel = () => {
    dispatch(setCentered())
    dispatch(setActiveView(View.CONNECTED_START))
  }

  const notFinishedCarousel = () => {
    setShowLastSlide(false)
    dispatch(setBottom())
  }

  const onLastSlide = () => {
    // do nothing
  }

  return (
    <div className="text-center h-screen">
      <section className="mt-auto h-full">
        <div className="text-13 mt-5 pb-6">{title}</div>
        {creationStories && (
          <CarouselComponent
            stories={creationStories}
            extraPagination={true}
            onLastSlide={() => onLastSlide()}
            onFinishedCarousel={() => finishedCarousel()}
            notFinishedCarousel={() => notFinishedCarousel()}
            setToLastSlide={skipToLastSlide}
          ></CarouselComponent>
        )}
        <div className="fixed bottom-3 w-full">
          <div className="flex relative justify-end bottom-3 mx-4 pb-2">
            <div className="w-20 h-12">
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key="introCreation"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={skipButtonAnimation}
                >
                  <Button
                    primary={false}
                    secondary
                    onClick={() => finishedCarousel()}
                  >
                    Skip
                  </Button>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default CreationStoryView
