import React, { FC, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import TiltedAdventures from './tiltedAdventures'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { selectActiveAdventure, setActiveAdventure } from 'store/exploreSlice'
import { selectUser } from 'store/userSlice'
import { Adventure } from 'store/types'

interface Props {
  clickableAdventure?: boolean
  animate?: boolean
}

const LifeCircle: FC<Props> = ({
  clickableAdventure = true,
  animate = true
}) => {
  const dispatch = useAppDispatch()
  const adventures = useAppSelector(selectUser).adventures
  const activeAdventure = useAppSelector(selectActiveAdventure)
  const adventuresClampedLength = 360

  const degrees = 360 / adventuresClampedLength
  const diameter = (adventuresClampedLength * 120) / Math.PI
  const radius = diameter / 2
  const swipeThresholdPixels = 20
  const extendedSwipeAreaLeft = 0.5
  const extendedSwipeAreaRight = 0.8
  const boundaryAngles = {
    left: extendedSwipeAreaLeft,
    right: -degrees * adventures.length + extendedSwipeAreaRight
  }

  const [isLeft, setIsLeft] = useState(false)
  const [initialCoordinates, setInitialCoordinates] = useState({ x: 0, y: 0 })

  const [initialLoad, setInitialLoad] = useState(false)

  const [currentAngle, setCurrentAngle] = useState(0)
  const [previousAngle, setCurrentToPreviousAngle] = useState(0)

  const [currentIndex, setCurrentIndex] = useState<number>(
    adventures.length - 1
  )

  useEffect(() => {
    adventures.map((adventure: Adventure, index: number) => {
      if (activeAdventure?.date === adventure.date) {
        setCurrentAngle(-degrees * index)

        if (!initialLoad) {
          setCurrentToPreviousAngle(-degrees * index)
        }
      }
    })
    setInitialLoad(true)
  }, [activeAdventure])

  const handleSwipeStartMobile = (event) => {
    setInitialCoordinates({
      x: event.targetTouches[0].clientX,
      y: event.targetTouches[0].clientY
    })
  }

  const handleSwipeMoveMobile = (event) => {
    const updatedCoordinateX = event.targetTouches[0].clientX
    const updatedCoordinateY = event.targetTouches[0].clientY
    handleSwipe(updatedCoordinateX, updatedCoordinateY)
  }

  const handleSwipeEnd = () => {
    const setFinalAngle = centerOfAdventureItem()
    setCurrentAngle(setFinalAngle)
    setCurrentToPreviousAngle(setFinalAngle)
    const adventureIndex = Math.abs(setFinalAngle) / degrees
    const roundedAdventureIndex = Math.round(adventureIndex)
    setCurrentIndex(roundedAdventureIndex)
    dispatch(setActiveAdventure(adventures[roundedAdventureIndex]))
  }

  const swipedAngleWithinBoundary = (swipeAngle: number) => {
    return swipeAngle < boundaryAngles.left && swipeAngle > boundaryAngles.right
  }

  const handleSwipe = (
    updatedCoordinateX: number,
    updatedCoordinateY: number
  ) => {
    const swipedDistance = getSwipedDistance(
      updatedCoordinateX,
      updatedCoordinateY
    )
    const swipeAngle = previousAngle + calculateAngle(swipedDistance)
    if (swipedAngleWithinBoundary(swipeAngle)) setCurrentAngle(swipeAngle)
  }

  const getSwipedDistance = (x: number, y: number) => {
    const diffX = initialCoordinates.x - x
    const diffY = initialCoordinates.y - y
    diffX < 0 ? setIsLeft(false) : setIsLeft(true)

    const swipeDistance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2))
    return Math.abs(diffX) > swipeThresholdPixels ? swipeDistance : 0
  }

  function calculateAngle(swipedDistance: number) {
    const angle = SSSTriangle(swipedDistance)
    const angleInRad = Math.acos(angle)
    const angleInDeg = (180 / Math.PI) * angleInRad
    return isLeft ? -angleInDeg : angleInDeg
  }

  const SSSTriangle = (swipedDistance: number) => {
    // SSS-triangle: Swiped angle = arccos(numerator / denominator)
    const powRadius = Math.pow(radius, 2)
    const powSwipedDistance = Math.pow(swipedDistance, 2)
    const numerator = 2 * powRadius - powSwipedDistance
    const denominator = radius * radius * 2
    return numerator / denominator
  }

  const centerOfAdventureItem = () => {
    const adventureIndex = Math.abs(currentAngle) / degrees
    const roundedAdventureIndex = Math.round(adventureIndex)
    return Math.round(roundedAdventureIndex) * -degrees
  }

  const selectPreviousAdventure = () => {
    if (currentIndex - 1 >= 0) {
      const newIndex = currentIndex - 1
      setCurrentIndex(newIndex)
      dispatch(setActiveAdventure(adventures[newIndex]))
    }
  }
  const selectNextAdventure = () => {
    if (currentIndex + 1 <= adventures.length - 1) {
      const newIndex = currentIndex + 1
      setCurrentIndex(newIndex)
      dispatch(setActiveAdventure(adventures[newIndex]))
    }
  }
  const addKeyboardNavigationEventListener = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      selectPreviousAdventure()
    }
    if (event.key === 'ArrowRight') {
      selectNextAdventure()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', addKeyboardNavigationEventListener)
    return () => {
      window.removeEventListener('keydown', addKeyboardNavigationEventListener)
    }
  }, [currentIndex])

  return (
    <div className="relative overflow-hidden w-screen h-1/2">
      <motion.div
        className="absolute top-2 left-1/2 rounded-full"
        animate={{
          rotate: currentAngle
        }}
        onTouchStart={handleSwipeStartMobile}
        onTouchMove={handleSwipeMoveMobile}
        onTouchEnd={handleSwipeEnd}
        style={{
          width: diameter + 'px',
          height: diameter + 'px',
          marginLeft: -(diameter / 2) + 'px'
        }}
      >
        <TiltedAdventures
          adventures={adventures}
          shouldAnimate={animate}
          clickableAdventure={clickableAdventure}
          degrees={degrees}
          diameter={diameter}
        />
      </motion.div>
    </div>
  )
}

export default LifeCircle
