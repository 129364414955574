import React, { FC, useState } from 'react'
import { motion } from 'framer-motion'
import Modal from 'components/modal/modal'
import ProductTitle from 'components/layout/productTitle'
import Section from 'components/layout/section'
import DataPolicyView from './dataPolicy'
import StoryBubble from '../storyBubble'
import { Consent } from 'store/types'
import { useAppDispatch, useAppSelector } from 'store/reduxHooks'
import { setConsent, setEmail, setUserName } from 'store/userSlice'
import { setActiveView, View } from 'store/viewSlice'
import { selectContent } from 'store/contentSlice'
import { inputAnimation } from 'public/framerMotionVariants'
import InputUserName from 'components/inputUserName'
import InputEmail from 'components/inputEmail'
import ConsentDataPolicyContent from 'components/consentDataPolicyContent'
import useScrollToTop from '../../hooks/useScrollToTop'
import { setCentered } from 'store/backgroundSlice'
import { StoryBubbleText } from './connectViewStorybubbleText'

const nameRegex = /^[a-zA-Z0-9 -]+$/
const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const consent: Consent = {
  id: 'memberTerms',
  value: true,
  source: 'source',
  comment: 'Trial terms'
}

interface Props {
  title: string
  bubbleText?: string
}

export type LogInSteps = {
  hasProvidedLegalUsername: boolean
  hasProvidedLegalEmail: boolean
  hasDataPolicyConsent: boolean
}

const ConnectView: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch()
  const text = useAppSelector(selectContent)
  const [isModalOpen, setModalState] = useState(true)
  const [consentCheckboxState, setConsentCheckboxState] = useState(null)
  const [localUserName, setLocalUserName] = useState(null)
  const [localEmail, setLocalEmail] = useState(null)
  const [modalContent, setModalContent] = useState<LogInSteps>({
    hasProvidedLegalUsername: false,
    hasProvidedLegalEmail: false,
    hasDataPolicyConsent: false
  })
  const [showPolicy, setShowPolicy] = useState(false)

  useScrollToTop('yes')
  dispatch(setCentered())

  const toggleModal = () => {
    setModalState(!isModalOpen)
  }

  const checkName = (name: string) => {
    const trimmedName = name.trim()
    return trimmedName?.search(nameRegex)
  }

  const checkEmail = (email: string) => {
    const trimmedEmail = email.trim()
    return trimmedEmail?.search(emailRegex)
  }

  const goToNextInput = (name: string): void => {
    const isNameValid = !checkName(name)
    setLocalUserName(name)

    if (isNameValid) {
      saveUserName(name)
      return setModalContent({
        ...modalContent,
        hasProvidedLegalUsername: true
      })
    }

    return setModalContent({
      ...modalContent,
      hasProvidedLegalUsername: false
    })
  }

  const goToTapView = (email: string): void => {
    const isEmailValid = !checkEmail(email)
    const isConsentValid = consentCheckboxState
    setLocalEmail(email)

    if (isEmailValid && isConsentValid) {
      return saveEmailWithConsent(email)
    }

    if (isEmailValid && !isConsentValid) {
      setConsentCheckboxState(false)
      return setModalContent({
        ...modalContent,
        hasProvidedLegalEmail: true
      })
    }

    return setModalContent({
      ...modalContent,
      hasProvidedLegalEmail: false,
      hasDataPolicyConsent: false
    })
  }

  const saveUserName = (value: string) => {
    dispatch(setUserName(value))
  }

  const saveEmailWithConsent = (value: string) => {
    dispatch(setEmail(value))
    dispatch(setConsent(consent))
    dispatch(setActiveView(View.TAP_AND_CONNECT))
  }

  const goBack = () => {
    dispatch(setActiveView(View.NOT_CONNECTED_START))
  }

  const checkboxEventHandler = (e) => {
    if (e === 'checkBox') {
      setConsentCheckboxState(!consentCheckboxState)
      setModalContent({
        ...modalContent,
        hasDataPolicyConsent: !consentCheckboxState
      })
    } else setShowPolicy(!showPolicy)
  }

  return (
    <div className="flex flex-col justify-between content-between w-full h-full place-content-between">
      <Section className="mt-5">
        <ProductTitle className="mb-3" name={title}></ProductTitle>
        <StoryBubble className="mt-5">
          <StoryBubbleText
            modalContent={modalContent}
            localUserName={localUserName}
            localEmail={localEmail}
            connectView={text.connectView}
          />
        </StoryBubble>
      </Section>

      {!showPolicy ? (
        <motion.div
          key="input-modal"
          variants={inputAnimation}
          initial="hidden"
          animate="visible"
        >
          <Modal
            isFullscreen={false}
            isClosable={false}
            isOpen={isModalOpen}
            onClose={toggleModal}
          >
            {!modalContent.hasProvidedLegalUsername ? (
              <InputUserName
                hasProvidedLegalName={modalContent.hasProvidedLegalUsername}
                currentName={localUserName}
                textConnectView={text.connectView}
                onClick={(localUserName) => goToNextInput(localUserName)}
              />
            ) : (
              <InputEmail
                hasProvidedLegalEmail={modalContent.hasProvidedLegalEmail}
                currentEmail={localEmail}
                textConnectView={text.connectView}
                onClick={(localEmail) => goToTapView(localEmail)}
              />
            )}
            <ConsentDataPolicyContent
              hasProvidedLegalPolicyConsent={modalContent.hasDataPolicyConsent}
              textConnectView={text.connectView}
              checkboxSelected={consentCheckboxState}
              onChange={(e) => checkboxEventHandler(e)}
              onShowPolicy={(e) => checkboxEventHandler(e)}
              onClose={goBack}
            />
          </Modal>
        </motion.div>
      ) : (
        <DataPolicyView setShowPolicy={setShowPolicy} />
      )}
    </div>
  )
}

export default ConnectView
