import React, { FC } from 'react'
import Image from 'next/image'
import cn from 'classnames'

interface NavbarProps {
  className?: string
  openAdventureView: () => void
  openCareRepairView: () => void
  openCoCreateView: () => void
  openUseMeView: () => void
  setContent?: (content: string) => void
}

const Navbar: FC<NavbarProps> = ({
  className,
  openAdventureView,
  openCareRepairView,
  openCoCreateView,
  openUseMeView
}) => {
  return (
    <div className="w-full fixed bottom-0 left-0">
      <div
        className={cn(
          'w-full',
          'h-83',
          'bg-white',
          'flex',
          'justify-around',
          'items-center',
          'p-2',
          'text-unnamed',
          'text-opacity-60',
          'text-10',
          className
        )}
      >
        <div className="flex flex-col" onClick={() => openAdventureView()}>
          <Image
            src={'/images/Icon-Adventure.svg'}
            alt={''}
            height="36"
            width="36"
          />

          <div>Adventures</div>
        </div>

        <div className="flex flex-col" onClick={() => openUseMeView()}>
          <Image src={'/images/Icon-Use.svg'} alt={''} height="36" width="36" />
          <div>Use me</div>
        </div>

        <div className="flex flex-col" onClick={() => openCareRepairView()}>
          <Image
            src={'/images/Icon-Care-Repair.svg'}
            alt={''}
            height="36"
            width="36"
          />
          <div>Care &amp; Repair</div>
        </div>

        <div className="flex flex-col" onClick={() => openCoCreateView()}>
          <Image
            src={'/images/Icon-Co-create.svg'}
            alt={''}
            height="36"
            width="36"
          />

          <div>Co-Create</div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
