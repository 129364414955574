import { FC } from 'react'
import cn from 'classnames'

interface LabelProps {
  className?: string
  children: string
}

const Label: FC<LabelProps> = ({ className, children }) => (
  <div className="flex justify-center w-full px-8">
    <div
      className={cn(
        'flex',
        'relative',
        'min-h-27',
        'min-w-72',
        'max-w-xs',
        'font-goudy-old-style',
        'my-3',
        'bg-white',
        'leading-snug',
        'mx-2',
        'rounded-full',
        'px-3',
        'text-12',
        'items-center',
        'justify-center',
        className
      )}
    >
      <p>{children}</p>
    </div>
  </div>
)

export default Label
