import React, { FC } from 'react'
import cn from 'classnames'

interface Props {
  className?: string
  numberOfItems?: number
  viewportSize: string
  active: boolean
}

const Pagination: FC<Props> = ({ active, viewportSize }) => {
  return (
    <div
      className={cn(
        [
          'w-0.6', 'h-0.6', 'flex', 'border', 'rounded-full', 'mx-0.3', 'border-black', 'transition-all', 'duration-350'
        ],
        viewportSize == 'small' ? [''] : ['-mt-2'],
        active ? ['bg-black'] : ['bg-gray opacity-20']
      )}
    ></div>
  )
}

export default Pagination
