import { FC, ReactNode } from 'react';
import cn from 'classnames';
import Paragraph from './paragraph';

interface Props {
  className?: string;
  children: ReactNode;
}

const BodyText: FC<Props> = ({ className, children }) => {
  return (
    <div className='flex max-w-full justify-center'>
      <Paragraph className={cn(['text-sm'], className)}>{children}</Paragraph>
    </div>
  );
};

export default BodyText;
