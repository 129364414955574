import Button from 'components/layout/button'
import React, { FC, useState } from 'react'
import Image from 'next/image'
import { useAppSelector } from 'store/reduxHooks'
import Modal from 'components/modal/modal'
import { selectContent } from 'store/contentSlice'
import TextBlock from 'components/layout/textBlock'
import Paragraph from 'components/layout/paragraph'
import H1 from 'components/layout/h1'
import ContentBlock from 'components/layout/contentBlock'
import MailToLink from 'components/mailToLink'
import { selectProductData } from 'store/productSlice'

interface Props {
  closeCareRepairView: () => void
}

const CareRepairModalView: FC<Props> = ({ closeCareRepairView }) => {
  const [isModalOpen, setModalState] = useState(true)
  const toggleModal = () => {
    setModalState(!isModalOpen)
    closeCareRepairView()
  }
  const { careAndRepairView } = useAppSelector(selectContent)
  const { name } = useAppSelector(selectProductData)

  if (!isModalOpen) {
    return null
  } else {
    return (
      <Modal
        isFullscreen={true}
        isClosable={true}
        isTransparent={false}
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <div className="text-center items-center">
          <H1 className="mb-40">{careAndRepairView?.title}</H1>
          {careAndRepairView.contentBlocks[String(name)] &&
            careAndRepairView.contentBlocks[String(name)].map(
              (contentBlock) => (
                <ContentBlock
                  key={contentBlock.heading}
                  heading={contentBlock.heading}
                  icon={contentBlock.icon}
                >
                  {contentBlock?.textParagraphs?.map((text) => (
                    <Paragraph className="mb-30 px-4">{text}</Paragraph>
                  ))}

                  {contentBlock?.textBlocks?.map((textblock) => (
                    <TextBlock
                      className="text-left mx-2"
                      heading={textblock.heading}
                    >
                      {textblock?.bulletList && (
                        <ul className="ml-4 mr-0.5 list-disc leading-21">
                          {textblock?.bulletList?.map((bullet) => (
                            <li className="pb-1">
                              <Paragraph>{bullet}</Paragraph>
                            </li>
                          ))}
                        </ul>
                      )}

                      {textblock?.textParagraph && (
                        <Paragraph className="mb-30">
                          {textblock?.textParagraph}
                        </Paragraph>
                      )}
                    </TextBlock>
                  ))}

                  {contentBlock?.ctaButtons &&
                    contentBlock?.ctaButtons.map((button) => (
                      <Button
                        primary={button.isPrimary}
                        isDisabled={button.isDisabled}
                      >
                        {button.email ? (
                          <MailToLink
                            email={button.email}
                            subject={button.subject}
                            body={button.body}
                            linkText={button.buttonText}
                          />
                        ) : button.targetUrl ? (
                          <a href={button.targetUrl} target="_blank">
                            {button.buttonText}
                          </a>
                        ) : (
                          button.buttonText
                        )}
                      </Button>
                    ))}
                </ContentBlock>
              )
            )}

          <div className="w-12 h-12 fixed right-4 bottom-6">
            <Button
              className="w-full flex items-center justify-center"
              onClick={() => toggleModal()}
            >
              <Image
                src={'/images/Icon-Close.svg'}
                alt={''}
                height="30"
                width="30"
              />
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default CareRepairModalView
