import React, { FC } from 'react'
import cn from 'classnames'

interface ColorCircleProps {
  className?: string
  colorName: string
}

const ColorCircle: FC<ColorCircleProps> = ({ className, colorName }) => {
  const trimmedColorName = colorName?.toLowerCase().trim().replace(/\s+/g, '')

  return (
    <div>
      <span>
        <div
          className={cn(
            'align-middle',
            'border-black',
            'rounded-full',
            'border',
            'inline-flex',
            'm-1.5',
            'p-px',
            className
          )}
        >
          <div
            className={cn(
              'w-4',
              'h-4',
              'border-white',
              'border-2',
              'rounded-full',
              className,
              {
                [`bg-${trimmedColorName}`]: colorName,
                'bg-black': !colorName
              }
            )}
          />
        </div>
      </span>

      <span className="align-middle font-thin text-13">{colorName}</span>
    </div>
  )
}

export default ColorCircle
