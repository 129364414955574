import React, { FC } from 'react';
import Image from 'next/image';
import Button from 'components/layout/button';
import { setActiveView, View } from 'store/viewSlice';
import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import ProductTitle from 'components/layout/productTitle';
import StoryBubble from 'components/storyBubble';
import LifeCircle from 'components/circleOfLife/lifeCircle';
import { selectActiveAdventure } from 'store/exploreSlice';
import Label from 'components/label';
import { setBottom, setCentered } from 'store/backgroundSlice';
import { selectUser } from 'store/userSlice';
import useScrollToTop from '../../hooks/useScrollToTop';
import { selectViewport } from 'store/viewportSlice';
import cn from 'classnames';
import Paragraph from 'components/layout/paragraph';
import Section from 'components/layout/section';

interface Props {
  title: string;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const ExploreView: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const activeAdventure = useAppSelector(selectActiveAdventure);
  const connectedDate = useAppSelector(selectUser).adventures[0].date;
  const viewportSize = useAppSelector(selectViewport);
  const milliSeconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;

  useScrollToTop();
  dispatch(setBottom());

  const activeAdventureDate = () => {  
    const activeAdventureDate = new Date(activeAdventure?.date);
    const monthIndex = activeAdventureDate.getMonth(); 
    const year = activeAdventureDate.getFullYear();
    const month = monthNames[monthIndex];
    return  month + '/' + year.toString();
  };

  const daysBetweenConnectedAndActivity = () => {
    const millSecConnected = Date.parse(connectedDate);
    const millSecActiveAdventure = Date.parse(activeAdventure?.date);
    const daysSinceConnected = calculateDiff(millSecConnected, millSecActiveAdventure);
    return daysSinceConnected.toString();
  };

  const calculateDiff = (millSecConnected: number, millSecActiveAdventure: number) => {
    const timeSinceConnected = millSecActiveAdventure - millSecConnected;
    let daysSinceConnected = timeSinceConnected / milliSeconds / seconds / minutes / hours;
    daysSinceConnected = Math.round(daysSinceConnected);
    return daysSinceConnected + 1;
  };

  const closeView = () => {
    dispatch(setCentered());
    dispatch(setActiveView(View.CONNECTED_START));
  };

  return (
    <div className="flex flex-col w-full h-full">
      <Section className="mt-5">
        <ProductTitle className="mb-3" name={title}></ProductTitle>
        <div className={cn(['text-center'], viewportSize === 'small' ? 
          [ 'mt-4'] : ['mt-10'],
        )}>
          <div className="p-4">
            <Label>{'Day ' + daysBetweenConnectedAndActivity()}</Label>
            <h1 className="py-2">Our journey</h1>
            <div className="text-14">
              <Paragraph>
                {activeAdventureDate()}
              </Paragraph>
            </div>
          </div>
          <div className={cn(viewportSize === 'small' ? 
          [ 'pt-4'] : ['pt-8'],
        )}>
            <StoryBubble>{activeAdventure?.text}</StoryBubble>
          </div>
          <div className="flex justify pt-4 h-48">
            <LifeCircle clickableAdventure={false} animate={false}></LifeCircle> 
          </div> 
          <div className="w-12 h-12 fixed right-4 bottom-6 z-10">
            <Button
              secondary
              className="w-full flex items-center justify-center"
              onClick={() => closeView()}
            >
              <Image
                src={'/images/Icon-Close.svg'}
                alt={''}
                height="30"
                width="30"
              />
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ExploreView;
