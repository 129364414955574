import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

const Section: FC<Props> = ({ className, children }) => {
  return (
    <section className={cn(['w-full', 'px-3'], className)}>{children}</section>
  );
};

export default Section;
