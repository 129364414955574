import { FC } from 'react'
import Image from 'next/image'
import cn from 'classnames'

type ProductImageProps = {
  name?: string
  image: string
  className?: string
}

const ProductImage: FC<ProductImageProps> = ({ name, image, className }) => (
  <div className={cn('relative', { 'h-48 w-48': !className }, className)}>
    <Image
      src={image}
      alt={name}
      layout="fill"
      objectFit="cover"
      className="rounded-full text-center"
      objectPosition="50% 70%"
    />
  </div>
)

export default ProductImage
