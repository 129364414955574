import React, { FC } from 'react'

interface YoutubePlayerProps {
  embedId: string
}

const YoutubePlayer: FC<YoutubePlayerProps> = ({ embedId }) => (
  <div className="p-2">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?mute=1&rel=0&enablejsapi=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Youtube video player"
    />
  </div>
)

export default YoutubePlayer
