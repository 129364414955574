import React, { FC, ReactNode } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import H2 from './h2';
import SeparatingLine from './separatingLine';

interface Props {
  className?: string;
  heading?: string;
  icon?: string;
  children: ReactNode;
}

const ContentBlock: FC<Props> = ({ className, heading, icon, children }) => {
  return (
    <div className={cn(['mb-30'], className)}>
      {icon && (
        <div className='mb-20px'>
          <Image src={`/images/${icon}`} alt={''} height='64' width='64' />
        </div>
      )}

      {heading && <H2 className='mb-20px px-12 leading-24'>{heading}</H2>}
      {children}
      <SeparatingLine />
    </div>
  );
};

export default ContentBlock;
