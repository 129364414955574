import React, { FC, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Svg } from 'store/types'

interface FirstConnectAnimatedImageProps {
  paths: Svg[]
  className?: string
  strokeWidth?: number
  shouldAnimate?: boolean
  ease?:
    | 'linear'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'circIn'
    | 'circOut'
    | 'circInOut'
    | 'backIn'
    | 'backOut'
    | 'backInOut'
    | 'anticipate'
}

const FirstConnectAnimatedImage: FC<FirstConnectAnimatedImageProps> = ({
  paths,
  className,
  strokeWidth = 0.5,
  shouldAnimate = false,
  ease = 'easeInOut'
}) => {
  const animation = useAnimation()

  const [inViewRef, isInView] = useInView({
    triggerOnce: true,
    threshold: 0.7
  })

  useEffect(() => {
    animation.start(isInView ? 'visible' : 'hidden')
  }, [animation, isInView])

  const pathVariants = {
    hidden: {
      pathLength: shouldAnimate ? 0 : 1
    },
    visible: {
      pathLength: 1,
      transition: {
        delay: 0.1,
        duration: 3,
        ease
      }
    }
  }

  return (
    <motion.svg
      className={className}
      ref={inViewRef}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 42.057"
      initial="hidden"
      animate={animation}
    >
      {paths.map(({ path, transformation }, i) => (
        <motion.path
          key={i}
          stroke="black"
          strokeWidth={strokeWidth}
          variants={pathVariants}
          d={path}
          transform={transformation}
        />
      ))}
    </motion.svg>
  )
}

export default FirstConnectAnimatedImage
